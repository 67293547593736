<template>
  <div>
    <router-view></router-view>
    <Affiliated></Affiliated>
  </div>
</template>

<script>
import Affiliated from "@/views/components/Affiliated"
export default {
  components:{
    Affiliated  
  },
  name: "tour",
  mounted() {
    this.$store.dispatch("updatePageHead", "出团")
  }
}
</script>

<style>

</style>